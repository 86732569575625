input[type="text"] {
  flex: 1;
  font-size: medium;
  border: none;
  /* border-radius: 100px !important;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 15px; */
  padding: 5px 10px;
}

input[type="text"]:focus {
  outline: none;
}

.search__input {
  width: 37vw;
  min-width: 300px;
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 999px;
  padding: 8px 20px;
  height: 30px;
  max-width: 560px !important;
  margin: 0 auto;
  margin-top: 40px !important;
}

.search__input:hover {
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.search__input:focus-within {
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.search__inputIcon {
  color: rgba(0, 0, 0, 0.4);
}
.search__buttons {
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.search__buttons > button {
  background-color: #f4f4f4;
  margin: 3px;
  border: 1px solid #f4f4f4;
  max-width: 80%;
  text-transform: capitalize;
  color: rgba(1, 1, 1, 0.7);
}

.search__buttons > button:hover {
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
  color: black;
  border: 1px solid gray;
  background-image: -webkit-linear-gradient(top, #f8f8f8, #f1f1f1);
}

.search__buttonsHidden {
  display: none !important;
}

@media only screen and (max-width: 500px) {
  .search__input {
    width: 75vw;
  }
}

.search__clearSearch {
  color: rgba(0, 0, 0, 0.4);
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.search__clearSearch:hover {
  transform: scale(1.1);
  color: rgba(0, 0, 0, 0.9);
}
