.searchPage_logo {
  height: 30px;
  width: auto;
  margin-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 40px;
}

.searchPage__header {
  display: flex;
  overflow-x: scroll;
  border-bottom: 1px solid lightgray;
  position: sticky;
  z-index: 100;
}

.searchPage__headerLeft {
  display: flex;
  align-items: flex-start;
  padding: 20px;
  padding-bottom: 0px;
  top: 0;
}

.searchPage__headerRight {
  width: 100%;
  display: flex;
  align-items: center !important;
  justify-content: flex-end;
  position: sticky;
  padding: 20px;
  padding-bottom: 0px;
  margin-bottom: 50px;
  top: 0;
  right: 20;
}

.searchPage__headerRight .MuiSvgIcon-root {
  float: right;
}

.search__input {
  margin-top: 0px !important;
}
.search__inputIcon {
  color: #1a73e8;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.searchPage__header::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.searchPage__header {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.searchPage_bar > .search > .search__input {
  margin: 0 0 !important;
  width: 100% !important;

  max-width: unset;
}
.search {
  width: 100% !important;
}

.searchPage__option {
  margin-left: 10px;
  padding-right: 6px;
  display: flex;
  align-items: center;
  margin-left: 12px;
}
.searchPage_bar {
  max-width: 720px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.searchPage__optionsLeft {
  align-items: center;
  display: flex;
}

.searchPage__optionsRight {
  align-items: center;
  display: flex;
}

.searchPage__options {
  min-width: 600px;
  display: flex;
  justify-content: space-between;
}
.searchPage__options a {
  font-size: small;
  font-weight: 510;
  color: rgba(0, 0, 0, 0.6) !important;
  text-decoration: none;
  padding-bottom: 10px;
}

.selected {
  border-bottom: 3px solid #1a73e8;
  color: #1a73e8 !important;
  text-decoration: none;
}

.selected > a {
  color: #1a73e8 !important;
}

.searchPage__option .MuiSvgIcon-root {
  padding: 1px;
  margin: 1px;
  color: rgba(0, 0, 0, 0.6) !important;
  font-size: medium;
  padding-bottom: 10px;
}

.searchPage__resultsCount {
  margin-top: 1px;

  font-size: 13px;
}

.searchPage__resultsLink {
  padding-top: 2px;
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #006621;
  font-size: medium;
}
.searchPage__resultsTitle {
  font-size: larger;
  text-decoration: none;
  color: #1a0dab;
}

.searchPage__resultsLink + .searchPage__resultsTitle:hover {
  text-decoration: underline;
}
.searchPage__resultsTitle:hover {
  text-decoration: underline;
  color: #1a0dab;
}

.searchPage__resultsTitle > b > p {
  font-weight: 500 !important;
  font-size: large;
  padding-bottom: 3px;
  text-emphasis: emphass;
}

.searchPage__result {
  margin-bottom: 40px;
  margin-top: 20px;
}

.searchPage__results {
  font-family: sans-serif;
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 700px) {
  .searchPage__results {
    max-width: 600px;
    margin-top: 10px;
    margin-left: 170px;
    margin-bottom: 100px;
  }
}

.searchPage__resultsSnippet {
  padding-top: 7px;
}

.searchPage__resultsImage {
  height: 20px;
  width: auto;
  margin-right: 10px;
}
